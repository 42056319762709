// css.ts
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  "my-confirm-button": {
    backgroundColor: "#fa541c",
    color: "#fff",
    padding: "6px 16px",
    borderRadius: 4,
    border: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    outline: 0,
    margin: "0 10px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    textDecoration: "none",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    minWidth: 64,
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  },

  "my-cancel-button": {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    outline: 0,
    border: "1px solid rgba(250, 84, 28, 0.5)",
    margin: "0 10px",
    borderRadius: 4,
    padding: "5px 15px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    textDecoration: "none",
    color: "#fa541c",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    minWidth: 64,
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },

  "my-swal-popup": {
    backgroundColor: "#fefefe",
    color: "#212121",
    borderRadius: 10,
  },
});

export default useStyles;
