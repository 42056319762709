export default class Payment {
    private readonly successUrl: string;
    private readonly cancelUrl: string;
    private readonly productPriceId: string;
    private readonly userId: string;
    private readonly email: string;
  
    constructor(x?: any) {
        if (typeof x === "object" && x !== null && x !== undefined) {
          this.successUrl = x.successUrl;
          this.cancelUrl = x.cancelUrl;
          this.productPriceId = x.productPriceId;
          this.userId = x.userId;
          this.email = x.email;
        }
        else {
            this.successUrl = "";
            this.cancelUrl = "";
            this.productPriceId = "";
            this.userId = "";
            this.email = "";
        }
    }
  
    getSuccessUrl(): string {
      return this.successUrl;
    }
  
    getPrice(): string {
      return this.productPriceId;
    }
  
    toJSON(): any {
      return {
        successUrl: this.successUrl,
        cancelUrl: this.cancelUrl,
        productPriceId: this.productPriceId,
        userId: this.userId,
        email: this.email
      };
    }
}
  