// Prod
export const firebaseConfig = {
    apiKey: "AIzaSyCu9AmLMBpbOxCBk8o_JLJdVTqXZeU17xM",
    authDomain: "loteri-app.firebaseapp.com",
    projectId: "loteri-app",
    storageBucket: "loteri-app.appspot.com",
    messagingSenderId: "1019908006184",
    appId: "1:1019908006184:web:00150a6d604f4f7482f01d",
    measurementId: "G-Z34GBSG1WJ",
    reCAPTCHA: "6LfiGNApAAAAAKEndoMPPMNKUyZQ1xWCk4if8fmZ" // public
};