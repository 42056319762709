import React, { useState } from "react";
import { saveContactForm } from "./../../api/contact";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import Contact from "../../models/contact";
import useCustomSwal from "./../Common/CustomSwal";

const contactEmpty = {
  name: "",
  email: "",
  comment: "",
};

const ContactForm = () => {
  const [formData, setFormData] = useState<Contact>(contactEmpty);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const CustomSwal = useCustomSwal();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
    setSuccess("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.comment) {
      CustomSwal({
        title: "Aviso",
        text: "Todos los campos son obligatorios.",
        icon: "warning",
      });
      return;
    }

    setLoading(true);

    try {
      const saveForm = await saveContactForm(formData);

      if (saveForm) {
        CustomSwal({
          title: "Aviso",
          text: "¡Mensaje enviado con éxito!",
          icon: "success",
        });
        setFormData(contactEmpty);
      } else {
        CustomSwal({
          title: "Aviso",
          text: "Ocurrió un error, inténtalo de nuevo.",
          icon: "error",
        });
      }
    } catch (err) {
      CustomSwal({
        title: "Aviso",
        text: "Ocurrió un error, inténtalo de nuevo.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" style={{ paddingTop: 150 }}>
      <Box
        sx={{
          boxShadow: 3,
          p: 4,
          borderRadius: 2,
          textAlign: "center",
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Contáctanos
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            disabled={loading}
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            disabled={loading}
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            disabled={loading}
            label="Comentario"
            name="comment"
            multiline
            rows={4}
            value={formData.comment}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              disabled={loading}
              onClick={() => {
                setError("");
                setFormData(contactEmpty);
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Enviar"}
            </Button>
          </Box>

          {error && (
            <Typography color="error">
              <>
                <br />
                {error}
              </>
            </Typography>
          )}
          {success && (
            <Typography color="success.main">
              <>
                <br />
                {success}
              </>
            </Typography>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default ContactForm;
