import { appCheck } from "../firebase";
import { getToken } from "firebase/app-check";
import { CONSTANT } from "../constants";
import Contact from "../models/contact";

export const saveContactForm = async (formData: Contact) => {
  const { token } = await getToken(appCheck, true);

  const request = {
    method: "POST",
    headers: {
      "x-firebase-appcheck": token,
    },
    body: JSON.stringify(formData),
  };

  try {
    const response = await fetch(CONSTANT.urlApiAddContact, request);

    if (!response.ok) {
      console.error("Error HTTP:", response.status);
      return false;
    }

    const data = await response.json();
    // console.log("response data", data);
    return true;
  } catch (error) {
    console.error("Error de red o fetch:", error);
    return false;
  }
};
