// beta
const urlFunc = "http://127.0.0.1:5001/loteria-mexicanapp/us-central1";
const urlWeb = "http://localhost:3000";

export const appSettings = {
  urlApiPayment: `${urlFunc}/payment`,
  urlApiWebhook: `${urlFunc}/webhook`,
  urlApiAddContact: `${urlFunc}/addContact`,
  success_url: `${urlWeb}/success`,
  cancel_url: `${urlWeb}/dashboard`,
  stripe_public_key:
    "pk_test_51P6vk1Cx7k12Jl8K9G9RdPSWLuDgtQMwTDcIBrYusJXROQEe8tGJV0VQ4usRoTRRew2rwGVoiImdqG6sCPThgY5f00kdC7kyCA",
  price_product1: "price_1P6w7FCx7k12Jl8K3CFc4AaS",
  price_product2: "price_1P6wAXCx7k12Jl8KmvacsfuM",
  analytics: "G-58WMCJMHLS",
};
