import { appSettings as appSettingsLocal } from "./appsettings.local";
import { appSettings as appSettingsBeta } from "./appsettings.beta";
import { appSettings as appSettingsProd } from "./appsettings.prod";

export const CONSTANT =
  process.env.REACT_APP_ENV === "prod"
    ? appSettingsProd
    : process.env.REACT_APP_ENV === "beta"
    ? appSettingsBeta
    : appSettingsLocal;
