import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Chip,
  CircularProgress,
  Container,
  Divider,
} from "@mui/material";
// useTheme

// import { loadStripe } from "@stripe/stripe-js";

import { RootState } from "../../redux/store";

// import useStyles from "./css";
import {
  ArrowDropDown,
  ArrowDropUp,
  AutoAwesome,
  CheckCircleRounded,
} from "@mui/icons-material";
import { CONSTANT } from "../../constants";
import Payment from "../../models/payment";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import useCustomSwal from "./../Common/CustomSwal";

export default function Pricing() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector((state: RootState) => state.auth);
  const gaEventTracker = useAnalyticsEventTracker("Auth");

  const [loading, setLoading] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const CustomSwal = useCustomSwal();
  // const classes = useStyles();

  // 'Se guarda en la nube',
  // 'Agrega hasta 55 figuras',
  // 'Personalización completa de tarjetas',
  // 'Imprime tus tarjetas y tablas',
  // 'Crea un archivo PDF de ellas',
  // 'Comparte por enlace o QR',
  // 'Juega desde la aplicación',
  // 'Reusable: elimina y carga de nuevo',

  const tiers = [
    {
      title: "Una",
      price: "15",
      description: [
        "50% descuento incluído",
        "Añade una lotería extra a tus loterías",
      ],
      buttonText: "Adquirir",
      buttonVariant: "outlined",
      productPriceId: CONSTANT.price_product1,
    },
    {
      title: "Cuatro",
      subheader: "", // Recomendable
      price: "50",
      description: [
        "50% descuento incluído",
        "Descuento al adquirir el paquete de 4 loterías",
      ],
      buttonText: "Adquirir",
      buttonVariant: "contained",
      productPriceId: CONSTANT.price_product2,
    },
  ];

  const handlePay = async (event: React.FormEvent, productPriceId: string) => {
    gaEventTracker(
      "payment_for",
      CONSTANT.price_product1 === productPriceId ? "1" : "4"
    );

    event.preventDefault();
    setLoading(true);

    const payment = new Payment({
      successUrl: CONSTANT.success_url,
      cancelUrl: CONSTANT.cancel_url,
      productPriceId,
      userId: user?.uid,
      email: user?.email,
    });

    try {
      await fetch(CONSTANT.urlApiPayment, {
        method: "POST",
        body: JSON.stringify(payment),
      })
        .then(async (res) => {
          return res.json();
        })
        .then((session) => {
          if (JSON.stringify(session).toString().indexOf("error") === -1)
            window.location = session.url;
          else {
            CustomSwal({
              title: "Error",
              text: "Ocurrió un error con la pasarela de pago",
              icon: "error",
            });
            setLoading(false);
          }
        })
        .catch((rejected) => {
          CustomSwal({
            title: "Error",
            text: "Ocurrió un error con la pasarela de pago",
            icon: "error",
          });
          console.error(rejected);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <span className="no-print">
      <Divider />

      {/* <Elements stripe={stripePromise}> */}
      <Container
        id="pricing"
        sx={{
          pt: { xs: 4, sm: 10 },
          // pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "75%" },
            textAlign: { sm: "center", md: "center" },
          }}
        >
          <Accordion variant="outlined" sx={{ border: 0 }}>
            <AccordionSummary
              // expandIcon={<ExpandMore />}
              onClick={() => setIsAccordionOpen((val) => !val)}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                component="h4"
                variant="h6"
                color="text.primary"
                style={{ margin: "auto" }}
              >
                ⭐&nbsp;&nbsp;Adquirir más
                {isAccordionOpen ? (
                  <ArrowDropUp
                    style={{ position: "relative", top: 5, left: 5 }}
                  />
                ) : (
                  <ArrowDropDown
                    style={{ position: "relative", top: 5, left: 5 }}
                  />
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {user ? (
                <>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ textAlign: { sm: "center", md: "center" } }}
                  >
                    Descubre la innovación en loterías personalizadas: crea y
                    guarda en la nube, agrega hasta 55 figuras con
                    personalización completa de tarjetas, imprime o convierte en
                    PDF, comparte fácilmente por enlace o QR, juega desde
                    nuestra aplicación y reinventa el juego.
                  </Typography>

                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mb: 5, mt: 5 }}
                  >
                    {tiers.map((tier) => (
                      <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === "Enterprise" ? 12 : 6}
                        md={5}
                      >
                        <Card
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 0, // 4
                          }}
                        >
                          <CardContent>
                            <Box
                              sx={{
                                mb: 1,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography component="h3" variant="h6">
                                {tier.title}
                              </Typography>
                              {(tier.subheader === "Recomendable" || true) && (
                                <Chip
                                  icon={<AutoAwesome />}
                                  label={"Oferta"} //tier.subheader
                                  size="small"
                                  sx={{
                                    background: (theme) =>
                                      theme.palette.mode === "light"
                                        ? ""
                                        : "none",
                                    backgroundColor: "primary.contrastText",
                                    "& .MuiChip-label": {
                                      color: "primary.dark",
                                    },
                                    "& .MuiChip-icon": {
                                      color: "primary.dark",
                                    },
                                  }}
                                />
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <Typography component="h3" variant="h4">
                                ${tier.price}
                              </Typography>
                              <Typography component="h3" variant="h6">
                                &nbsp; MXN
                              </Typography>
                            </Box>
                            <Divider
                              sx={{
                                my: 2,
                                opacity: 0.2,
                                borderColor: "grey.500",
                              }}
                            />
                            {tier.description.map((line) => (
                              <Box
                                key={line}
                                sx={{
                                  py: 1,
                                  display: "flex",
                                  gap: 1.5,
                                  alignItems: "center",
                                  textAlign: "left",
                                }}
                              >
                                <CheckCircleRounded
                                  sx={{
                                    width: 20,
                                  }}
                                />
                                <Typography
                                  component="text"
                                  variant="subtitle2"
                                >
                                  {line}
                                </Typography>
                              </Box>
                            ))}
                          </CardContent>
                          <CardActions>
                            <Button
                              fullWidth
                              disabled={loading}
                              //variant={tier?.buttonVariant! || "contained"}
                              variant="contained"
                              onClick={(e) => handlePay(e, tier.productPriceId)}
                            >
                              {tier.buttonText}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    textAlign: { sm: "center", md: "center" },
                  }}
                >
                  Autentícate para adquirir loterías que se guarden en la nube.
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>

        {/*
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Quiero más
        </Typography> 
        <br />
        <Typography variant="body1" color="text.secondary">
          Descubre la innovación en loterías personalizadas: crea y guarda en la nube, agrega hasta 55 figuras con 
          personalización completa de tarjetas, imprime o convierte en PDF, comparte fácilmente por enlace o QR, 
          juega desde nuestra aplicación y reinventa el juego con funcionalidad reusable.
        </Typography>
      </Box>

      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'Professional' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'Professional' ? 'primary.main' : undefined,
                background:
                  tier.title === 'Professional'
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: tier.title === 'Professional' ? 'grey.100' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.subheader === 'Recomendable' && (
                    <Chip
                      icon={<AutoAwesome />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === 'Professional' ? 'grey.50' : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; MXN
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRounded
                      sx={{
                        width: 20,
                        color:
                          tier.title === 'Professional'
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === 'Professional' ? 'grey.200' : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  // variant={tier.buttonVariant}
                  component="a"
                  href="/material-ui/getting-started/templates/checkout/"
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
       */}

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
      {/* </Elements> */}
    </span>
  );
}
