// swalConfig.ts
import Swal from "sweetalert2";
import useStyles from "./css";

const useCustomSwal = () => {
  const classes = useStyles();

  return (options: any) =>
    Swal.fire({
      ...options,
      customClass: {
        confirmButton: classes["my-confirm-button"],
        cancelButton: classes["my-cancel-button"],
        popup: classes["my-swal-popup"],
        ...(options.customClass || {}),
      },
      buttonsStyling: false,
    });
};

export default useCustomSwal;
