// prod
const urlFunc = "https://us-central1-loteri-app.cloudfunctions.net";
const urlWeb = "https://loteri.app";

export const appSettings = {
  urlApiPayment: `${urlFunc}/payment`,
  urlApiWebhook: `${urlFunc}/webhook`,
  urlApiAddContact: `${urlFunc}/addContact`,
  success_url: `${urlWeb}/success`,
  cancel_url: `${urlWeb}/dashboard`,
  stripe_public_key:
    "pk_live_51P6vk1Cx7k12Jl8KIsymoONAZ3jnfTo5xcm4M9O2seDnRDRslekGxUhwmKgLq3Dq9uYb1XOk1iS4wS4ELDGNLRHI00qFkaG1P4",
  price_product1: "price_1PBOI4Cx7k12Jl8K15oLHDwq",
  price_product2: "price_1PBOICCx7k12Jl8Ke3Llv71P",
  analytics: "G-Z34GBSG1WJ",
};
